// Libraries
import React from 'react'
import { format } from 'date-fns'

// Components
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'
import Label from '../../../components/Label';
import spinner from '../../../components/Spinner'

// Contexts
import useBookingContext from '../../../hooks/use-booking-context';

// Utils
import Strings from '../../../utils/strings'

function ConfirmBooking({next, prev}) {
    const context = useBookingContext();
    const [ loading, setLoading ] = React.useState(false);

    let fee = context.fee;

    const handleNext = async(e) => {
        e.preventDefault();

        if(loading)
            return;
        
        setLoading(true);

        const response = await context.createAppointment();
        if(response.status === 200) { 
            setLoading(false);
            next();
            return;
        }
        setLoading(false);
    }

    const handleEdit = (e) => {
        e.preventDefault();
        prev();
    }

    return (
        <div className='pb-4'>
            <Heading title={Strings.en.bookNow.confirmation.heading} desc={Strings.en.bookNow.confirmation.subHeading} />
            <Label title='Name/Email' value={`${context.name}/${context.email}`} />
            <Label title='Mobile No' value={context.mobileNo} />
            <Label title='Consultant Category' value={context.categories.find(c => c.selected)?.name} />
            <Label title='Meeting Channel & Date' value={ `${context.channels.find(c => c.selected)?.name} - ${format(new Date(context.date), 'do MMM, yyyy')}` } />
            <Label title='Note' value={context.description} />

            { context.consultantCode && 
               <Label title='Consultant Code' value={context.consultantCode} />
            }

            <Label title='Consultation Fee' value={
                <>
                { fee?.pre_discounted_fee === fee?.total_amount 
                    ? <p>Your consultation fee is <b>{Strings.en.currency}{fee?.total_amount}</b> </p>    
                    : <>
                        <p>First consultation is <b>{Strings.en.currency}{fee?.total_amount}</b> </p>
                        <p>Your next consultation will be <b>{Strings.en.currency}{fee?.pre_discounted_fee}</b></p>
                    </>
                }
                </>
            } />
            
            <div className='flex flex-row mt-16 justify-between items-center'>
                <Button onClick={ handleNext }>{ loading ? spinner : <b>Complete</b> }</Button>
                <a href='#edit' onClick={handleEdit} className='text-primary font-bold'>Edit</a>
            </div>
        </div>
    )
}

export default ConfirmBooking;
