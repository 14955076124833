import React from 'react'

const initialState = {
    openPanel: false,
    panel: '',
    selectedConsultant: '',
    feedbackToken: ''
}

const AppContext = React.createContext();

function appReducer(state, action) {
    switch (action.type) {
        case 'open-panel':
            return {
                ...state,
                openPanel: true,
                panel: action.panel
            }

        case 'close-panel':
            return {
                ...state,
                openPanel: false,
                panel: ''
            }

        case 'select-consultant':
            return {
                ...state,
                openPanel: true,
                panel: 'book-now',
                selectedConsultant: action.consultant
            }

        case 'store-feedback-token':
            return {
                ...state,
                feedbackToken: action.token
            }
            
        default:
            return state;
    }
}

function AppProvider({ children }) {
    const [state, dispatch] = React.useReducer(appReducer, initialState);
    const value = {state, dispatch};
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export {AppContext};
export default AppProvider
