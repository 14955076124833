// Libraried
import React from 'react'

// Components
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'

// Utils
import Strings from '../../../utils/strings'

function PaymentSuccessful({
    name,
    amount,
    meetingCategory,
    consultantName
}) {
    return (
        <>
          <Heading title={Strings.en.payment.paymentSuccessful} desc='' />  

          <div className='mt-16'>
              <p>Thank you <b>{name}</b>,</p>
              <p>We have received your payment of <b>{Strings.en.currency} {amount}</b>.</p>
              <p>Your meeting with <b>{meetingCategory}</b> consultant, <b>{consultantName}</b> is confirmed!</p>
              <p className='mt-4'>You’ll receive an email with your meeting details shortly.</p>
          </div>

          <div className='mt-24'>
              <Button href={Strings.url}>Ok</Button>
          </div>
        </>
    )
}

export default PaymentSuccessful
