const Strings = {
    url: "https://anampi.com",
    supportEmail: "help@assisme.com",
    en: {
        footer: "Anampi @",
        currency: 'GH¢',
        payment: {
            heading: 'Pay to confirm your booking',
            subHeading: '',
            bookingSummary: 'Booking Summary',
            paymentSuccessful: 'Payment Successful',
            authorization: 'Authorization',
            verifyOtp: {
                heading: 'Verify your number',
                subHeading: 'Enter the code you received'
            },
            timeout: {
                heading: 'Timed Out',
                subHeading: ''
            },
            failed: {
                heading: 'Payment Failed',
                subHeading: ''
            }
        },
        bookNow: {
            enterEmail: {
                heading: 'Let’s get you started',
                subHeading: 'Enter your email address'
            },
            enterOtp: {
                heading: 'Let’s get you started',
                subHeading: 'Enter your verification code'
            },
            details: {
                heading: 'Make an appointment',
                subHeading: ''
            },
            confirmation: {
                heading: 'Confirm your booking details',
                subHeading: ''
            },
            success: {
                heading: 'Boking Received',
                subHeading: 'You will receive a confirmation email as soon as we find you the perfect consultant'
            },
        }
    }
}

export default Strings;