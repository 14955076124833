import React from 'react'
import Heading from '../../../components/Heading'
import Strings from '../../../utils/strings'

function Timedout() {
    return (
        <div>
            <Heading title={Strings.en.payment.timeout.heading} desc='' />

            <div className='mt-16'>
              <p>Your payment deadline has elapsed. Kindly <a className='text-primary' href={ `${Strings.url}/booking` }>click here</a> to make a new booking request.</p>
          </div>

        </div>
    )
}

export default Timedout
