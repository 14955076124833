// Libraries
import React from 'react'

// Components
import InitPayment from './components/init-payment'
import PaymentRequestSent from './components/payment-request-sent'
import VerifyOtp from './components/verify-otp'
import Timedout from './components/timedout'
import PaymentSuccessful from './components/payment-successful'
import spinner from '../../components/Spinner'

// Contexts
import PaymentContextProvider from '../../context/payment-context';

import ApiClient from '../../services/api-client';
import PaymentFailed from './components/payment-failed'

export default function Payment() {
    const [position, setPosition] = React.useState(0);
    const [payId, setPayId] = React.useState(0);
    const [data, setData] = React.useState({});

    React.useEffect(() => {
        setPosition(0);
        getAppointmentInfo();

        // eslint-disable-next-line 
    }, []);

    const getAppointmentInfo = async() => {
        const urlData = document.URL.split('/')
        const token = urlData[urlData.length - 1];

        try{
            const response = await ApiClient.get('/api/v1/payment_requests/appointment_info', {
                headers: {
                    'X-TOKEN': token
                }
            })

            if(response?.status === 200) { 
                setData(response.data.data);
                setPosition(1);
            } else {
                setPosition(5);
            }
        } catch(e) {
            setPosition(5);
        }
    }


    const getForm = (position) => {
        if (position === 1) {
            return <InitPayment next={() => setPosition(2)} setPayId={setPayId} data={ data } />
        } else if (position === 2) {
            return <VerifyOtp next={(value) => setPosition(value ?? 3)} payId={payId} mobileNo={payId} />
        } else if (position === 3) {
            return <PaymentRequestSent payId={payId} next={(value) => setPosition(value ?? 4)} />
        } else if (position === 4) {
            return <PaymentSuccessful name={data?.client_name} amount={data?.fee?.total_amount} meetingCategory={data?.category} consultantName={data?.consultant_name} />
        } else if (position === 5) {
            return <Timedout />
        } else if (position === 6) {
            return <PaymentFailed next={() => setPosition(2)} amount={data?.fee?.total_amount} setPayId={setPayId} />
        } else {
            return (
                <div className='p-4 bg-primary max-w-max mx-auto'>
                    { spinner }
                </div>
            );
        }
    }

    return (
        <PaymentContextProvider>
            <div className='w-screen max-w-3xl mx-4 mx-auto px-4 md:px-8'>
                { getForm(position) }
            </div>
        </PaymentContextProvider>
    )
}
