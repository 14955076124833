// Libraries
import React from 'react'

// Components
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'
import Form from '../../../components/Form'
import MobileMoney  from '../../../components/Form/components/MobileMoney'
import Spinner from '../../../components/Spinner';

// Utils
import Strings from '../../../utils/strings'

// Contexts
import usePaymentContext from '../../../hooks/use-payment-context';
import BookingDetails from './booking-details'


function InitPayment({next, setPayId, data}) {
    const [processing, setProcessing] = React.useState(false);
    const context = usePaymentContext();
    
    React.useEffect(() => {
        const urlData = document.URL.split('/')
        const token = urlData[urlData.length - 1];
        context.setXTokent(token);
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data) => {
        setProcessing(true);
        const response = await context.makePayment(data.momo_number, data.momo_network);
        if (response.status === 200) { 
            const payId = response.data.data.id;
            setPayId(payId);
            context.setMomoNumber(data.momo_number);
            next();
        } else {
            
        }

        setProcessing(false);
    }

    return (
        <>
        <Heading title={Strings.en.payment.heading} desc={Strings.en.payment.subHeading} />
        <Form onSubmit={onSubmit} setDefault={null}>
            {({ 
                register,
                formState: { errors },
                setValue,
                getValues
            }) => {

                return (
                <>
                    <p className='text-4xl'><b>Fee: {Strings.en.currency} { data.fee.total_amount }</b></p>
                    <h1 className='text-primary font-bold text-3xl mt-16'>{Strings.en.payment.bookingSummary}</h1>
                    <BookingDetails data={data} />
                    <div className='mt-16'>
                        <MobileMoney 
                            title="Enter momo number and select network"
                            numberInputName="momo_network"
                            networkSelectName="momo_network"
                            placeholder="022 000 0100"
                            register={register}
                            setValue={setValue}
                            isRequired
                            error={errors?.momo_number || errors?.momo_network}
                        />
                    </div>

                    <div className="mt-12">
                        <Button>{ processing ? Spinner : 'Pay Now'}</Button>
                    </div>

                </>
            )}}
        </Form>

        </>
    )
}

export default InitPayment
