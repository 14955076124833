import React from 'react'

// Components
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'
import Form from '../../../components/Form'
import MobileMoney  from '../../../components/Form/components/MobileMoney'
import Spinner from '../../../components/Spinner';

// Utils
import Strings from '../../../utils/strings'

// Contexts
import useBookingContext from '../../../hooks/use-booking-context';

function PaymentFailed({
    amount,
    next,
    setPayId
}) {
    const [processing, setProcessing] = React.useState(false);
    const context = useBookingContext();

    const onSubmit = async (data) => {
        setProcessing(true);
        const response = await context.makePayment(data.momo_number, data.momo_network);
        if (response.status === 200) { 
            const payId = response.data.data.id;
            setPayId(payId);
            next();
        } else {
            
        }

        setProcessing(false);
    }

    return (
        <div>
            <Heading title={Strings.en.payment.failed.heading} desc='' className='text-primary-error' />

            <div className='mt-16'>
                <p>
                    Your payment of <b>{Strings.en.currency} {amount}</b> for your appointment failed.
                </p>
            </div>

            <div className='mt-16'>
                <Form onSubmit={onSubmit} setDefault={null}>
                    {({ 
                        register,
                        formState: { errors },
                        setValue,
                        getValues
                    }) => {

                        return (
                        <>
                            <div className='mt-16'>
                                <MobileMoney 
                                    title="Enter momo number and select network"
                                    numberInputName="momo_network"
                                    networkSelectName="momo_network"
                                    placeholder="022 000 0100"
                                    register={register}
                                    setValue={setValue}
                                    isRequired
                                    error={errors?.momo_number || errors?.momo_network}
                                />
                            </div>

                            <div className="mt-12">
                                <Button>{ processing ? Spinner : 'Try Again'}</Button>
                            </div>

                        </>
                    )}}
                </Form>
            </div>

        </div>
    )
}

export default PaymentFailed;
