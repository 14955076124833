import ClassNames from 'classnames'

function Button({ loading, children, className, bg, icon, href, ...props }) {

    const defaultClasses = [
        `bg-${bg ? bg : 'primary'}`,
        'block', 
        'font-bold',
        'text-white', 'font-medium',
        'text-base', 'md:text-lg',
        'py-3', 'px-8', 'rounded-2xl',
        'text-center',
        {'px-16': icon === ''},
        {'px-5': icon},
        href ? 'max-w-max' : 'w-auto'
    ];

    if(href) {
        return (
            <a href={href} className={ClassNames(defaultClasses)}
            {...props}>
                {loading
                ? 'please wait...'
                : children }
            </a>
        )
    }

    return (
        <button className={ClassNames(defaultClasses)}
        {...props}>
            {loading
            ? 'please wait...'
            : children }
        </button>
    )
}

export default Button
