import React from 'react'

const Checkbox = React.forwardRef(({ error, children, ...props }, ref) => {
    return (
        <label className="inline-flex">
            <input 
                ref={ref}
                {...props}
                type="checkbox" 
                className="form-checkbox outline-none text-primary rounded w-6 h-6" 
            />
            <span 
                className={`ml-4 leading-5 ${error && 'text-red'}`}
            >{children}</span>
        </label>
    )
})

export default Checkbox
