import React from 'react'
import Button from '../../components/Button';
import Form from '../../components/Form';
import Input from '../../components/Form/components/Input';

import useAppContext from '../../hooks/useAppContext';
import ApiClient from '../../services/api-client';

const Question = ({ feedback, onAnswer }) => {
    const { question, answer_a, answer_b, answer_c, answer_d, id } = feedback;
    const answerStyle = 'px-4 py-3 border border-primary rounded-2xl px-4 lg_px-7';
    const [options, setAnswered] = React.useState({
        answer_a: {content: answer_a, active: false, style: answerStyle},
        answer_b: {content: answer_b, active: false, style: answerStyle},
        answer_c: {content: answer_c, active: false, style: answerStyle},
        answer_d: {content: answer_d, active: false, style: answerStyle}
    });

    const answer = ({answer, opt}) => {
        const newAnswers = Object.entries(options).map(([key, item]) => 
            key === opt
            ? {...item, content: item.content, active: true, style: item.style + ' bg-primary text-white'}
            : {...item, active: false, style: answerStyle}
        );
        setAnswered(newAnswers);
        onAnswer({
            question_id: id,
            answer
        });
    }

    return <div>
        <p className="lg:text-lg text-base mb-4">{question}</p>
        <div className="flex mb-4 lg:mb-6 lg:space-x-4 space-x-3">
            {Object.entries(options).map(([key, value], i) => value.content && <div 
                key={i} 
                className={`${value.style} cursor-pointer`}
                onClick={() => answer({answer: value.content, opt: key})}
            >
                {value.content}
            </div>)}
        </div>
    </div>
}

function Feedback() {
    const {dispatch, state: { feedbackToken }} = useAppContext();
    const [questions, setQuestions] = React.useState([]);
    const [answers, setAnswer] = React.useState([]);
    const questionsLengthRef = React.useRef(0);
    const [stage, setStage] = React.useState('asking');

    const manageAnswers = (answer) => {
        let exist = answers.find(item => item.question_id === answer.question_id);
        let incoming = answers;
        if(exist) {
            incoming = incoming.map(item =>
                item.question_id === answer.question_id
                ? answer
                : item
            )
        } else {
            incoming = [...answers, answer];
        }
        
        setAnswer(incoming);
    }

    const onSubmit = async (data) => {
        if (answers.length > 0 && questionsLengthRef.current === answers.length) {
            setStage('submit');
            try {
                const response = await ApiClient.post('api/v1/feedbacks', {
                    comment:data.comment,
                    feedback: answers
                    },
                    {headers: { 'X-TOKEN': feedbackToken }}
                );

                if(response?.status === 201 || response?.status === 200) {
                    setStage('thank-you');
                    setTimeout(() => {
                        dispatch({type: 'close-panel'});
                    }, 1200);
                } else {
                    setStage('failed');
                }
            } catch (error) {
                setStage('failed');
            }
        }
    }

    React.useEffect(() => {
        (async () => {
            const response = await ApiClient.get('/api/v1/feedback_questions', {
                headers: {
                    'X-TOKEN': feedbackToken
                }
            });

            if(response?.status === 200) {
                const { data } = response.data;
                setQuestions(data);
                questionsLengthRef.current = data.length
            }

        })();
        return () => {
            dispatch({type: 'close-panel'})
        }
        // eslint-disable-next-line
    }, []);
    

    return (
        <div className="flex flex-col w-full justify-center items-center">
            <h1 className="text-primary lg:text-6xl text-3xl font-bold lg:mb-16 mb-8">Help us rate Anampi</h1>
            {questions.length <= 0 && <div className={`text-black transition duration-150`}>loading</div>}
            <div className={`max-w-full relative transition duration-700 transform ${questions ? 'translate-y-0' : 'translate-y-10'}`}>
                {stage !== 'asking' &&
                    <div className="z-10 absolute w-full h-full bg-primary-light bg-opacity-90 flex justify-center items-center">
                        {stage === 'submit' && <span className="text-primary-dark text-2xl font-bold">submitting...</span>}
                        {stage === 'thank-you' && <span className="text-primary text-2xl font-bold">Thank You for the feedback.</span>}
                        {stage === 'failed' && <span className="text-primary-error text-2xl font-bold">Something went wrong!</span>}
                    </div>
                }
                {questions && questions.map((item, i) => <Question feedback={item} onAnswer={(ans) => manageAnswers(ans)} />)}
                {questions?.length > 0 && 
                    <Form onSubmit={onSubmit} setDefault={null}>
                        {({ 
                            register,
                            formState: { errors },
                            trigger, clearErrors, handleSubmit, control,
                            setValue,
                            getValues,
                            ...formMethods
                        }) => {

                            return (
                                <>
                                    <Input
                                        {...register('comment')}
                                        rows={5}
                                        isMultiLine
                                        autoComplete="off"
                                        title="Additional Feedback"
                                        placeholder="Type here"
                                    />

                                    <div className="mt-12">
                                        <Button>Submit</Button>
                                    </div>
                                </>
                            )
                        }}
                    </Form>
                }
            </div>
        </div>
    )
}

export default Feedback
