export default function sanitizeTailwindClasses(existing, overwrite) {
    if(overwrite === undefined) return existing;

    const withoutOverwrite = existing.split(' ').filter(c => {
        const utilityPrefix = c.match(/^[^-]*[^ -]/g)[0];
        let find;
        if(utilityPrefix){
            find = new RegExp("(\\W?)("+utilityPrefix+")(\\W*)(\\w*)","g");
        }
        
        // find && console.log('found: ', utilityPrefix);
        // find && console.log('overwrite: ', overwrite.match(find)?.length);
        const isDuplicated = find && overwrite.match(find)?.length;

        return (isDuplicated && isDuplicated > 0) ? false : true;
    });
    
    return withoutOverwrite.join(' ')+" "+overwrite;
}