import { useLayoutEffect, useEffect } from 'react';
import { Link } from 'react-scroll';
import Slider from 'react-slick';
import { useParams, useLocation } from 'react-router';
// import "slick-carousel/slick/slick-theme.css";

import HeroImage from '../../images/hero-image.jpg';
import FeedbackImage from '../../images/feedback.jpg';
import WhatsAppIcon from '../../images/whatsapp.png';
import EmailIcon from '../../images/email-icon.png';
import Logo from '../../images/logo.png';
import LogoGreen from '../../images/logo-green.png';
import Button from './components/Button';
import FinanceImage from '../../images/finance.jpg';
import FitnessWellnessImage from '../../images/fitness-wellness.jpg';
import RelationshipMarriageImage from '../../images/relationship-marriage.jpg';
import HealthMedicalImage from '../../images/health-medical.jpg';

import StepConsultant from '../../images/step-consult.svg';
import StepDate from '../../images/step-date.svg';
import StepChat from '../../images/step-chat.svg';

import useAppContext from '../../hooks/useAppContext';

const Container = ({ children, ...props }) => {
    return <div className="max-w-7xl mx-auto px-5 relative" {...props}>{children}</div>
}

const CONSULTANTS = [
    {
        title: 'Fitness & Wellness',
        desc: 'Improve your mental and physical wellbeing with expert advice',
        slug: 'fitness-wellness',
        image: FitnessWellnessImage
    },
    {
        title: 'Finance',
        desc: 'Expert advice on corporate and personal finance matters.',
        slug: 'finance',
        image: FinanceImage
    },
    {
        title: 'Relationship & Marriage',
        desc: 'Transform your relationships with expert advice',
        slug: 'relationship-marriage',
        image: RelationshipMarriageImage,
    },
    {
        title: 'Health & Medical',
        desc: 'Your top health concerns addressed by our health professionals',
        slug: 'health-medical',
        image: HealthMedicalImage,
    }
]

export default function Home() {
    const {dispatch} = useAppContext();
    let { token, feedback_id } = useParams();
    const location = useLocation();

    const openPanel = (panel) => {
        dispatch({
            type: 'open-panel',
            panel: panel
        });
    }

    useLayoutEffect(() => {
        const hamburger = document.querySelector('button.mobile-menu-button');
        const mobileMenu = document.querySelector('.mobile-menu');

        hamburger.addEventListener("click", () => {
            mobileMenu.classList.toggle('hidden');
        })
    }, []);

    useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is_sticky");
            } else {
                elementId.classList.remove("is_sticky");
            }
        });  
        window.scrollTo(0, 0); 
    });

    useEffect(() => {
        if(feedback_id) {
            dispatch({
                type: 'store-feedback-token',
                feedback_id
            });
            openPanel('feedback');
        }

        if(token) {
            openPanel('payment');
        }
        // eslint-disable-next-line
    }, [feedback_id, token]);

    useEffect(() => {
        if(location.pathname === '/tnc') {
            openPanel('terms');
        }
        // eslint-disable-next-line
    }, [location]);


    const handleBookNow = ({type, consultant}) => {
        dispatch({
            type: type,
            consultant: consultant
        });
        openPanel('book-now');
    }

    return (
        <>
            <div className="flex relative w-full h-screen z-10" id="home" style={{
                backgroundImage: `url(${HeroImage})`,
                backgroundPosition: 'top center',
                backgroundSize: 'cover'
            }}>
                <div className="w-full" id="navbar">
                    <nav>
                        <Container>
                            <div className="flex justify-between">
                                <div>
                                    <a href="/" className="flex items-center py-4 px-2">
                                        <img src={Logo} alt="Logo" className="w-32 lg:w-48 regular nav-brand" />
                                        <img src={LogoGreen} alt="Logo" className="w-32 lg:w-48 green nav-brand" />
                                    </a>
                                </div>

                                <div className="md:hidden flex items-center">
                                    <button className="outline-none mobile-menu-button">
                                        <svg
                                            className="w-6 h-6"
                                            x-show="!showMenu"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                        <path d="M4 6h24M4 12h24M4 18h24"></path>
                                        </svg>
                                    </button>
                                </div>

                                <div className="hidden md:flex items-center space-x-1 navigation">
                                    <Link
                                        className="py-4 px-2 text-white font-semibold cursor-pointer"
                                        activeClass="active" to="home" spy={true} smooth={true}
                                        >Home</Link
                                    >
                                    <Link
                                        activeClass="active" to="how-it-works" spy={true} smooth={true}
                                        className="py-4 px-2 text-white font-semibold hover:text-green-500 transition duration-300 cursor-pointer"
                                        >How it works</Link
                                    >
                                    <Link
                                        activeClass="active" to="consultants" spy={true} smooth={true}
                                        className="py-4 px-2 text-white font-semibold hover:text-green-500 transition duration-300 cursor-pointer"
                                        >Consultants</Link
                                    >
                                    <Button
                                        onClick={() => openPanel('book-now')}
                                        className="uppercase"
                                        >Book Now</Button>
                                </div>
                            </div>

                            {/* Mobile Menu */}
                            <div className="hidden mobile-menu bg-primary-light -mx-5">
                                    <ul className="">
                                        <li><Link to="home"  spy={true} smooth={true}className="block text-sm px-2 py-4 text-text hover:bg-green-500 hover:text-primary-light">Home</Link></li>
                                        <li><Link to="how-it-works" spy={true} smooth={true} className="block text-sm px-2 py-4 text-text hover:bg-green-500 hover:text-primary-light transition duration-300">How it works</Link></li>
                                        <li><Link to="consultants" spy={true} smooth={true} className="block text-sm px-2 py-4 text-text hover:bg-green-500 hover:text-primary-light transition duration-300">Consultants</Link></li>

                                        <li><span className="block text-sm px-2 py-4 text-text hover:bg-green-500 hover:text-primary-light transition duration-300 cursor-pointer" onClick={() => openPanel('book-now')}>Book Now</span></li>
                                    </ul>
                                </div>
                        </Container>
                    </nav>
                </div>
            
                <div className="mt-auto w-full">
                    <div className="max-w-7xl mx-auto mb-40 lg:mb-36 px-5 flex">
                        <div className="bg-black bg-opacity-50 rounded-xl pt-10 pb-8 pl-8 pr-6 lg:pr-0 lg:pl-14 lg:pt-2 lg:pb-9 w-auto text-white lg:w-2/4">
                            <h1 className="lg:text-6xl text-3.7xl font-light">
                                Connect with your 
                                <span className="font-bold block">perfect EXPERT </span>
                            </h1>
                            <p className="max-w-md text-base lg:text-lg mt-5">At Anampi, we understand your needs and have just the right expert to advise you.</p>

                            <Button className="mt-6" onClick={() => openPanel('book-now')}>Get me an expert</Button>
                        </div>
                    </div>
                </div>
            </div>
            
            <Container id="how-it-works">
                <div className="pt-16 pb-16">
                    <div className="pt-4 pb-3">
                        <h1 className="text-text font-bold text-2.2xl lg:text-4.7xl text-center">How Anampi works</h1>

                        <div className="flex flex-col lg:flex-row justify-between mt-14 lg:mt-20 space-y-14 lg:space-y-0">
                            <div className="flex-1 flex-col justify-center px-4">
                                <div className="square"><img src={StepConsultant} alt="Step 1" className="absolute w-full h-full" /></div>
                                <div className="mt-5">
                                    <div className="flex">
                                        <span className="bg-primary rounded-t-full rounded-br-full w-8 h-8 block mr-4 text-white font-bold text-lg text-center py-0.5">1</span>
                                        <div className="flex-1"><p className="text-text text-base lg:text-lg font-bold">Select a consultant category</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 flex-col justify-center px-4">
                                <div className="square"><img src={StepDate} alt="Step 2" className="absolute w-full h-full" /></div>
                                <div className="mt-5">
                                    <div className="flex">
                                        <span className="bg-primary rounded-t-full rounded-br-full w-8 h-8 block mr-4 text-white font-bold text-lg text-center py-0.5">2</span>
                                        <div className="flex-1"><p className="text-text text-base lg:text-lg font-bold">Pick a date for your appointment</p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 flex-col justify-center px-4">
                                <div className="square"><img src={StepChat} alt="Step 3" className="absolute w-full h-full" /></div>
                                <div className="mt-5">
                                    <div className="flex">
                                        <span className="bg-primary rounded-t-full rounded-br-full w-8 h-8 block mr-4 text-white font-bold text-lg text-center py-0.5">3</span>
                                        <div className="flex-1"><p className="text-text text-base lg:text-lg font-bold">Speak with your consultant</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>


            <div className="bg-primary-light pt-12 pb-20 lg:pt-32 lg:pb-40 relative" id="consultants">
                <div className="lg:absolute w-full lg:top-1/2 lg:transform lg:-translate-y-1/2">
                <Container>
                    <div className="lg:flex">
                        <div className="text-center lg:text-left">
                            <h1 className="text-primary font-bold text-3.7xl lg:text-6xl mb-2 lg:mb-4 whitespace-nowrap">Our consultants</h1>
                            <p className="max-w-md text-base lg:text-lg">Anampi consultants are experts with a wealth of industry experience ready to help you.</p>
                            <Button className="mt-8 mb-11 mx-auto lg:mt-6 lg:mx-0 lg:mb-0" onClick={() => openPanel('book-now')}>BOOK NOW</Button>
                        </div>
                    </div>
                </Container>
                </div>

                <div className="flex w-full">
                    <div className="lg:w-6/12"></div>
                    <div className="lg:w-8/12 overflow-hidden">
                        <div className="lg:pl-20 pl-4 pb-10">
                            <Slider
                                dots
                                arrows={false}
                                infinite
                                adaptiveHeight
                                autoplay
                                autoplaySpeed={3800}
                                slidesToShow={1}
                                slidesToScroll={1}
                                variableWidth={true}
                                responsive={[
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 3
                                        }
                                    },
                                    {
                                        breakpoint: 600,
                                        settings: {
                                            slidesToShow: 2,
                                        }
                                    },
                                    {
                                        breakpoint: 480,
                                        settings: {
                                            slidesToShow: 1,
                                        }
                                    }
                                ]}
                            >
                                {CONSULTANTS.map((item, i) => <div key={i}>
                                    <div  
                                        className='mr-5 lg:mr-10 item flex items-end bg-primary bg-opacity-25 w-154 h-303 lg:w-299 lg:h-589 rounded-14 lg:rounded-26 bg-cover'
                                        style={{backgroundImage: `url(${item.image})`}}
                                    >
                                        <div className="p-2 lg:p-4 text-white">
                                            <h4 className="lg:text-30 text-2xl leading-5 lg:leading-none mb-2 lg:mb-4">{item.title}</h4>
                                            <p className="text-xs lg:text-lg mb-7">{item.desc}</p>
                                            <Button className="text-primary bg-white lg:w-44 w-28 text-center lg:text-base text-xs" onClick={() => handleBookNow({type: 'select-consultant', consultant: item.slug})}>BOOK NOW</Button>
                                        </div>
                                    </div>
                                </div>)}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>

            <Container>
                <div className="lg:px-24 lg:py-16 py-16">
                    <div className="lg:flex lg:justify-between py-3 text-center lg:text-left">
                        <div className="w-60 h-52 lg:w-auto lg:h-auto overflow-hidden rounded-19 lg:rounded-32 mx-auto lg:mx-0 mb-6 lg:mb-0">
                            <img src={FeedbackImage} alt="" className="-mt-12 lg:-mt-0 w-full" />
                        </div>
                        <div className="lg:pl-6 flex flex-col justify-center">
                            <h1 className="text-2.2xl lg:text-4.7xl text-text font-bold">Feedback</h1>
                            <p className="px-4 lg:px-0">We value your feedback. Tell us what you think about this service</p>

                            <div className="flex flex-col items-center lg:flex-row mt-6">
                                <Button href="https://wa.me/233545337272" target="_blank" className="flex py-1 whitespace-nowrap lg:py-1.5 pl-2 lg:pl-4 items-center rounded-full bg-transparent text-text text-left border-primary border flex-1 w-45 lg:w-66 text-xs lg:text-base font-medium">
                                    <img src={WhatsAppIcon} alt="" className="mr-1 lg:mr-2 w-7 lg:w-10" /> Chat on WhatsApp</Button>
                                <Button href="mailto:support@anampi.com?subject=Anampi Feedback" className="flex py-1 whitespace-nowrap lg:py-1.5 pl-2 lg:pl-4 items-center rounded-full bg-transparent text-text text-left border-primary border flex-1 w-45 lg:w-66 text-xs lg:text-base font-medium lg:ml-6 mt-6 lg:mt-0">
                                    <img src={EmailIcon} alt="" className="mr-1 lg:mr-2 w-7 lg:w-10" /> Send us an email</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <div className="bg-footer pt-6 pb-4 lg:pb-5 text-xs lg:text-sm">
                <Container>
                    <div className="flex flex-col lg:flex-row">
                        <div className="mb-4"><img src={LogoGreen} alt="" className="w-90 h-23 lg:w-full lg:h-full" /></div>
                    </div>
                    <p className="">We understand your needs and have just the right expert to advise you.</p>

                    <div className="flex lg:items-end lg:justify-between">
                        <div className="mt-6 flex flex-wrap lg:w-full whitespace-nowrap">
                            <Link to="home" spy={true} smooth={true} className="text-primary mr-4 lg:mr-12 cursor-pointer">About us</Link>
                            <Link to="how-it-works" spy={true} smooth={true} className="text-primary mr-4 lg:mr-12 cursor-pointer">How it works</Link>
                            <span onClick={() => openPanel('terms')} className="text-primary mr-4 lg:mr-12 whitespace-nowrap cursor-pointer">Terms &amp; conditions</span>
                            <span onClick={() => openPanel('privacy')} className="text-primary mr-4 lg:mr-12 cursor-pointer">Privacy policy</span>
                            <p className="lg:mr-auto hidden lg:flex">© 2021 Anampi</p>
                            <p className="lg:mr-auto lg:hidden">Designed by Mobiverse with love</p>
                        </div>
                        <p className="lg:mr-auto hidden lg:block lg:whitespace-nowrap">Designed by Mobiverse with love</p>
                    </div>
                </Container>
            </div>
        </>
    )
}
