import React from 'react';
import { AppContext } from '../context/app-context';

function useAppContext() {
    const context = React.useContext(AppContext);

    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider')
    }

    return context;
}

export default useAppContext;