import { useEffect } from 'react'

import useAppContext from '../hooks/useAppContext'

import BookNow from '../pages/book-now';
import Feedback from '../pages/Feedback/Feedback';
import Terms from '../pages/Terms';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import Payment from '../pages/payment';

function Panel() {
    const {state: {openPanel, panel}, dispatch} = useAppContext();

    useEffect(() => {
        if(openPanel) {
            document.body.classList.add('overflow-hidden');
        }

        return () => {
            document.body.classList.remove('overflow-hidden');
        }
    }, [openPanel])

    return (
        <div className={`${openPanel && 'fixed w-full h-full top-0 bg-black bg-opacity-25 z-20'}`}>
            
        <div 
            className={`bg-primary-light fixed right-0 top-0 w-screen lg:w-5/6 h-screen z-30 pt-20 lg:pt-0  lg:rounded-l-32 transition-all transform ${openPanel ? 'translate-x-0' : 'translate-x-full'}`} 
            style={{boxShadow: '0 0 12px 0 rgba(0,0,0,.1),0 10px 30px 0 rgba(0,0,0,.2)'}}
        >
            <div className="flex flex-col h-full">
                <div className="h-20">
                    <div 
                    className="fixed top-4 left-4 w-46 h-46 rounded-full bg-white shadow-lg flex items-center justify-center cursor-pointer" 
                    onClick={() => dispatch({type: 'close-panel'})}>
                        <span className="text-30 text-primary font-bold -mt-1">&times;</span>
                    </div>
                </div>
                
                <div className="flex-grow overflow-y-scroll px-4 lg:px-0">
                        {{
                            'book-now': <BookNow />,
                            'feedback': <Feedback />,
                            'terms': <Terms />,
                            'privacy': <PrivacyPolicy />,
                            'payment': <Payment />
                        }[panel]}
                </div>
            
                <div className="h-20"></div>
            </div>
        </div>
        </div>
    )
}

export default Panel
