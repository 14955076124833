function Heading(props) {
    const {
        title,
        desc,
        className
    } = props;
    return (
        <div className="mt-4 mb-8">
            <h1 className={'text-3xl md:text-6xl font-black text-primary mb-6 ' + className}>{title}</h1>
            {desc ? <p className="text-base md:text-lg font-normal max-w-sm text-dark mb-6">{desc}</p> : ''}
        </div>
    )
}

export default Heading
