// Libraries
import React from 'react'

// Components
import BookingSuccessful from './components/BookingSuccessful'
import ConfirmBooking from './components/ConfirmBooking'
import Details from './components/Details'
import EnterEmail from './components/EnterEmail'
import VerifyEmail from './components/VerifyEmail'

// Contexts
import BookingContextProvider from '../../context/booking-context';

export default function BookNow() {
    const [position, setPosition] = React.useState(0);

    React.useEffect(() => {
        setPosition(1);
    }, []);


    const getForm = (position) => {
        if (position === 1) {
            return <EnterEmail next={() => setPosition(2)} />
        }
        else if (position === 2) {
            return <VerifyEmail next={() => setPosition(3)} />
        }
        else if (position === 3) {
            return <Details next={() => setPosition(4)} />
        }
        else if (position === 4) {
            return <ConfirmBooking next={() => setPosition(5)} prev={() => setPosition(3)} /> 
        }
        else if (position === 5) {
            return <BookingSuccessful />
        }
    }

    return (
        <BookingContextProvider>
            <div className='w-full max-w-3xl mx-auto md:px-8'>
                { getForm(position) }
            </div>
        </BookingContextProvider>
    )
}
