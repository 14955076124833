import { useState, useEffect } from 'react'
import Card from "../../Card"
import Label from "./Label"


function FormControl({error, isRequired, children, name, title}) {
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setErrorMessage(error?.message)
    }, [error]);

    return (
        <Card>
            {errorMessage && 
                <span className="flex absolute h-3 w-3 top-7 left-0 -mt-1.5 -ml-1.5">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-400"></span>
                </span>
            }
            <Label id={name}>
                {errorMessage 
                    ? <span className="text-red">{errorMessage}</span> 
                    : title
                }
                {!isRequired && <span className="text-muted"> (optional)</span>}
            </Label>
            {children}
        </Card>
    )
}

export default FormControl
