import { useState } from 'react'
import { useForm } from 'react-hook-form'
import mixpanel from 'mixpanel-browser';

// Components
import Input from '../../../components/Form/components/Input'
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'

// Utils
import Strings from '../../../utils/strings'
import useBookingContext from '../../../hooks/use-booking-context';

import AuthService from '../../../services/auth-service'
import Spinner from '../../../components/Spinner';

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function EnterEmail({ next }) {
    const [processing, setProcessing] = useState(false);
    const context = useBookingContext();

    const handleLoginResp = async(response) => {
        if (response?.data?.data) { 
            const data = response?.data?.data;

            const id = data.id;
            const email = data.email;

            context.setEmail(email);
            context.setUserId(id);

            setProcessing(false);
            next();
        } else {
            // setErrors(response.data.errors);
            setProcessing(false);
        }
    }

    const { 
        register, 
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = async data => {
        setProcessing(true);

        const response = await AuthService.login(data.email);
        await handleLoginResp(response);

        mixpanel.register({
            email: data.email
        });
        mixpanel.track('sign-in');
    };
    
    return (
        <>
            <Heading title={Strings.en.bookNow.enterEmail.heading} desc={Strings.en.bookNow.enterEmail.subHeading} />
            <form onSubmit={handleSubmit(onSubmit)} className='mt-20'>
                <Input 
                    isRequired
                    title="Email"
                    type="email"
                    name= 'email'
                    placeholder='e.g. example@yours.com'
                    autoComplete="off"
                    error={errors?.email}
                    {...register('email', {
                        required: {
                            value: true, 
                            message: "Email"
                        },
                        pattern: {
                            value: EMAIL_PATTERN,
                            message: "Email must be valid."
                        }
                    })}
                />


                <div className="mt-16 text-center">
                    <Button>{ processing ? Spinner : 'Continue'}</Button>
                </div>
            </form>
        </>
    )
}

export default EnterEmail
