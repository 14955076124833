import React from 'react'

function Label({title, value}) {
    return (
        <div className='w-full mb-12'>
            <p className='text-primary text-lg'>{ title }</p>
            <p className='mt-2'>
                { value }
            </p>
        </div>
    )
}

export default Label;
