import React, { useEffect } from 'react'
import { useForm } from "react-hook-form"

function Form({
    onSubmit,
    children,
    setDefault
}) {
    const methods = useForm();

    useEffect(() => {
        if(setDefault) {
            methods.reset(setDefault);
        }
    }, [setDefault, methods])
    
    return (
        <form onSubmit={methods.handleSubmit(onSubmit)}>{children(methods)}</form>
    );
};

export default Form
