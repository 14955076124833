import React from 'react'

function Terms() {
    return (
        <div className="px-4 py-10 lg:px-28 doc">
            <h2 className="mb-4 lg:mb-8 font-bold text-2xl">Terms of Service</h2>
            
        <p>Please read these terms of service (“terms of service”, “terms”) carefully before using the Anampi.com website (“website”, “service”, ”the site”, “site”) operated by Mobiverse Solutions Company Limited (“us”, “we”, “our”).</p>



        <p><strong>The Service</strong></p>



        <p>Anampi.com provides the avenue for users to easily request and get professional advise in any area of expertise from seasoned and qualified personnel in various fields. On the site, users submit a request to be connected to a professional consultant who can advise in their specific area of need over various remote channels.</p>



        <p><strong>Conditions of use</strong></p>



        <p>By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. Anampi.com only grants use and access of this website, its products, and its services to those who have accepted its terms.</p>



        <p><strong>Privacy policy</strong></p>



        <p>Before you continue using our website, we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.</p>



        <p><strong>Age restriction</strong></p>



        <p>You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this agreement. Anampi.com assumes no responsibility for liabilities related to age misrepresentation.</p>



        <p><strong>Intellectual property</strong></p>



        <p>You agree that all materials, products, and services provided on this website are the property of Anampi.com, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute Anampi.com’s intellectual property in any way, including electronic, digital, or new trademark registrations.</p>



        <p>You grant Anampi.com a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</p>



        <p><strong>User accounts</strong></p>



        <p>As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.</p>



        <p>If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly.</p>



        <p>Anampi reserves all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.</p>



        <p><strong>Prohibited Activities</strong></p>



        <p>You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.&nbsp;</p>



        <p>As a user of the Site, you agree not to:</p>



        <ul>
        <li>systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
        <li>make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
        <li>use a buying agent or purchasing agent to make purchases on the Site.</li>
        <li>use the Site to advertise or offer to sell goods and services.</li>
        <li>circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.</li>
        <li>engage in unauthorized framing of or linking to the Site.</li><li>trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
        <li>make improper use of our support services or submit false reports of abuse or misconduct.</li>
        <li>engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
        <li>interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.</li>
        <li>attempt to impersonate another user or person or use the username of another user.</li>
        <li>use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
        <li>use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
        <li>decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site.</li>
        <li>attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site.</li>
        <li>harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.</li>
        <li>delete the copyright or other proprietary rights notice from any Content.</li>
        <li>copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
        <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
        <li>use the Site in a manner inconsistent with any applicable laws or regulations.</li>
        </ul>



        <p><strong>Applicable law</strong></p>



        <p>By visiting this website, you agree that the laws of Ghana, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Anampi.com and you, or its business partners and associates.</p>



        <p><strong>Disputes</strong></p>



        <p>Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court of Ghana and you consent to exclusive jurisdiction and venue of such courts.</p>



        <p><strong>Indemnification</strong></p>



        <p>You agree to indemnify Anampi.com and its affiliates and hold Anampi.com harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.&nbsp;</p>



        <p><strong>Limitation on liability</strong></p>



        <p>Anampi.com is not liable for any damages that may occur to you as a result of your misuse of our website.</p>



        <p>Anampi.com reserves the right to edit, modify, and change this Agreement any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between Anampi.com and the user, and this supersedes and replaces all prior agreements regarding the use of this website.</p>
        <p></p>
        <p>&nbsp;</p>
        </div>
    )
}

export default Terms
