import ClassNames from 'classnames'

function Card({ children }) {
    return (
        <div className={ClassNames('bg-white', 'p-4', 'shadow-md', 'rounded-md', 'mb-5', 'relative')}>
            {children}
        </div>
    )
}

export default Card
