import React from 'react'

import FormControl from '../FormControl'
import SelectNetwork from './components/SelectNetwork'


function MobileMoney({
    title, isRequired,
    register, 
    numberInputName,
    placeholder,
    setValue,
    error, defaultValue,
    helpText,
    disabled
}) {

    const handleSelect = (selected) => {
        setValue('momo_network', selected);
    }

    return (
        <FormControl {...{isRequired, title, error}}>
            <div className="mt-2 relative">
                <div className="flex justify-center">
                <SelectNetwork {...{handleSelect, defaultValue:defaultValue?.momo_network}} disabled={disabled} />
                <input
                    {...register('momo_number', {
                        required: {
                            value: true,
                            message: 'Please enter momo number.'
                        },
                        minLength: {
                            value: 10,
                            message: "Phone number must be 10 digits."
                        },
                        maxLength: {
                            value: 10,
                            message: "Phone number must be 10 digits."
                        },
                        pattern: {
                            value: /^[0][0-9]+$/i,
                            message: 'Please provide valid number staring with zero'
                        }
                    })}
                    defaultValue={defaultValue?.momo_number}
                    disabled={disabled}
                    type="tel"
                    placeholder={placeholder ? placeholder : ''}
                    id={numberInputName}
                    autoComplete="off"
                    className="ml-5 border-0 outline-none w-full h-11 text-base md:text-lg" 
                />
                <input type="hidden" value={''} defaultValue={defaultValue?.momo_network} {...register('momo_network', {
                    required: {
                        value: true,
                        message: 'Please select the network'
                    }
                })} />
                </div>
            </div>

            {helpText &&
                <div className="mt-3 -mr-4 -ml-4">{helpText}</div>
            }
        </FormControl>
    )
}

export default MobileMoney
