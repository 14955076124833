function Label({
    children,
    id
}) {
    return (
        <label className="block text-base font-medium text-dark" htmlFor={id ? id : ''}>
            {children}
        </label>
    )
}

export default Label
