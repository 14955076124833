import ApiClient from './api-client';


async function login(email) {
    try{
        const response = await ApiClient.post('/api/v1/client/login', {
            email
        });
        return response;
    }
    catch(e){
        return e.response;
    }
}

async function verifyCode(email, code) {
    try{
        const response = await ApiClient.post('/api/v1/client/verify_code', {
            email,
            code
        });
        return response;
    }
    catch(e){
        return e.response;
    }
}

const AuthService = {
    login,
    verifyCode
};

export default AuthService;
