// Libraries
import React from 'react'
import { format } from 'date-fns'

import ApiClient from '../services/api-client';


export const BookingContext = React.createContext();

const BookingContextProvider = ({ children }) => {
    var minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [consultantCode, setConsultantCode] = React.useState('');
    const [date, setDate] = React.useState(null);
    const [token, setToken] = React.useState('');
    const [userId, setUserId] = React.useState(0);
    const [code, setCode] = React.useState('');
    const [channels, setChannels] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [consultationFee, setConsultationFee] = React.useState(0);
    const [mobileNo, setMobileNo] = React.useState('');
    const [fee, setFee] = React.useState({});

    async function createAppointment() {
        try{
            
            let data = {
                client_id: userId,
                category_id: categories.find(cat => cat.selected)?.id,
                problem_summary: description,
                meeting_channel_id: channels.find(cat => cat.selected)?.id,
                meeting_date: format(new Date(date), 'Y-MM-dd'),
                client_name: name,
                phone: mobileNo,
                consultant_code: consultantCode
            };

            const response = await ApiClient.post('/api/v1/create_booking', 
                {
                    ...data,
                },
                {
                    headers: { 
                        Authorization: `Bearer ${token}` 
                    },
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    async function resendBookingOTP(emailAddr) {
        try{
            const response = await ApiClient.post(`api/v1/client/resendCode`, 
                {
                    email: emailAddr
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    async function getChannels() {
        try{
            const response = await ApiClient.get(`/api/v1/meeting_channels?client_id=${userId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    async function getCategories() {
        try{
            const response = await ApiClient.get(`/api/v1/categories?client_id=${userId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    async function getFee(categoryId, channelId) {
        try{
            const response = await ApiClient.get(`/api/v1/consultation_fees?client_id=${userId}&category_id=${categoryId}&meeting_channel_id=${channelId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    const data = {
        name, 
        setName, 
        email, 
        setEmail, 
        description, 
        setDescription, 
        date, 
        setDate, 
        channels,
        setChannels,
        minDate,
        token,
        setToken,
        userId,
        setUserId,
        code,
        setCode,
        createAppointment,
        getChannels,
        getCategories,
        categories,
        setCategories,
        consultationFee,
        setConsultationFee,
        setMobileNo,
        mobileNo,
        consultantCode,
        setConsultantCode,
        resendBookingOTP,
        fee,
        setFee,
        getFee
    }
    
    return (
        <BookingContext.Provider value={{ ...data }}>
            { children }
        </BookingContext.Provider>
    )
}

export default BookingContextProvider;

export {
    BookingContextProvider
}