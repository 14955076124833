import React from 'react'
import { format } from 'date-fns'
// import DatePicker from 'react-date-picker'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormControl from './FormControl';

import CalendarIcon from '../../../images/calendar.svg';


function getMinDate() {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
}

function getDate(daysToAdd) {
    var date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    return date;
}

function getMaxDate() {
    var date = new Date();
    date.setDate(date.getDate() + 60);
    return date;
}

function isSameDate(firstDate, secondDate) {
    return (
        firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
    );
  }

function Datepicker({
    register,
    title,
    isRequired,
    error,
    defaultValue,
    setValue,
    allowedDays
}) {
    const [hasDate, setHasDate] = React.useState(false);
    const [value, onChange] = React.useState(new Date(defaultValue));

    console.log(defaultValue);

    const handleCalenderClick = ()  => {
        document.querySelector('.react-datepicker__input-container input[type=text]').click();
    }

    const getExcludeDates = () => {
        if (!allowedDays?.length)
            return null;

        let disabledDates = [];
        let newDate = getMinDate();
        const daysDiff = 60;
        
        for(var x = 0; x < daysDiff; x++) { 
            if(allowedDays.indexOf(newDate.getDay()) < 0) {
                disabledDates.push(getDate(x+1));
            }

            newDate.setDate(newDate.getDate() + 1);
        }

        // Change selected date if its part of disabled dates
        if (disabledDates.find((d) => isSameDate(d, value))) {
            setTimeout(() => {
                handleCalenderClick();
            }, 500);
        }

        return disabledDates;
    }
    
    return (
        <FormControl {...{title, isRequired, error}}>
            <div className="z-0">
                <input type="hidden" {
                    ...register('date', {
                        required: {value: true, message: "Select Date"}
                    })
                    } 
                    value={hasDate ? value : ''}
                />
                <div className='flex mt-2'>
                    <img src={CalendarIcon} alt='Calendar' onClick={handleCalenderClick}/>
                    <DatePicker
                        placeholderText="Select Date"
                        excludeDates={getExcludeDates()}
                        dateFormat="y-MM-dd"
                        minDate={getMinDate()}
                        maxDate={getMaxDate()}
                        selected={hasDate ? value : null}
                        onChange={(v) => {
                            const selected = new Date(v);
                            setValue('date', format(selected, "y-MM-dd"));
                            onChange(v);
                            setHasDate(true);
                        }}
                    />
                </div>
            </div>
        </FormControl>
    )
}

export default Datepicker
