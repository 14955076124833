import React from 'react'
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import Strings from '../../../utils/strings';


export default function BookingSuccessful() {

    return (
        <div className='flex justify-center flex-col h-full'>
            <Heading title={Strings.en.bookNow.success.heading} desc='' />
            <Heading title='' desc={Strings.en.bookNow.success.subHeading} />

            <div className='flex mt-16'>
                <Button href={Strings.url}>Ok</Button>
            </div>
        </div>
    )
}
