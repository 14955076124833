// Libraries
import React from 'react'

import ApiClient from '../services/api-client';


export const PaymentContext = React.createContext();

const PaymentContextProvider = ({ children }) => { 
    const [xtoken, setXTokent] = React.useState('');
    const [momoNumber, setMomoNumber] = React.useState('');

    async function makePayment(momoNo, network) {
        try{
            const response = await ApiClient.post(`api/v1/payment_requests`, 
                {
                    momo_number: momoNo,
                    momo_network: network
                },
                {
                    headers: { 'X-TOKEN': xtoken }
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    async function verifyPaymentCode(payId, code) {
        try{
            const response = await ApiClient.post(`/api/v1/payment_requests/${payId}/confirm`, 
                {
                    otp_code: code
                },
                {
                    headers: { 'X-TOKEN': xtoken }
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    async function getPaymentStatus(payId) {
        try{
            const response = await ApiClient.get(`api/v1/payment_requests/${payId}/status`, 
                {
                    headers: { 'X-TOKEN': xtoken }
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    async function resendPaymentOTP(payId) {
        try{
            const response = await ApiClient.get(`api/v1/payment_requests/${payId}/resend_code`, 
                {
                    headers: { 'X-TOKEN': xtoken }
                }
            );
            return response;
        }
        catch(e){
            return e.response;
        }
    }

    const data = {
        setXTokent,
        getPaymentStatus,
        makePayment,
        resendPaymentOTP,
        verifyPaymentCode,
        momoNumber,
        setMomoNumber
    }
    
    return (
        <PaymentContext.Provider value={{ ...data }}>
            { children }
        </PaymentContext.Provider>
    )
}

export default PaymentContextProvider;

export {
    PaymentContextProvider
}