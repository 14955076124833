import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Home from './pages/home';
import Panel from './components/Panel'
import AppProvider from './context/app-context';
// import BookNow from './pages/book-now';
// import Payment from './pages/payment';


function App() {
  return (
    <AppProvider>
      <Panel />
      <BrowserRouter>
        <Routes>
            {/* <Route path="booking" element={<BookNow />} /> */}
            <Route path="payment/:token" element={<Home />} />

            <Route path="tnc" element={<Home />} />
            <Route path="feedback/:feedback_id" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route index element={<Home />} />

        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
