import { useState } from 'react'
import PinInput from 'react-pin-input'
import { useForm } from 'react-hook-form'
import mixpanel from 'mixpanel-browser';

// Components
import Button from '../../../components/Button'
import Card from '../../../components/Card'

// Utils
import Strings from '../../../utils/strings'
import Spinner from '../../../components/Spinner';
import usePaymentContext from '../../../hooks/use-payment-context';
import ResendOTP from '../../../components/ResendOTP';


function VerifyOtp({ payId, next, mobileNo }) {
    const context = usePaymentContext();
    const { handleSubmit, setValue } = useForm();
    const [processing, setProcessing] = useState(false);
    const [pinEle, setPinEle] = useState();
    const[error, setError] = useState('');


    const onSubmit = async (data) => {
        if (!data.pin) 
            return;

        setProcessing(true);

        mixpanel.track('verify-payment-code', {
            code: data.pin
        });

        setError("");
        const response = await context.verifyPaymentCode(payId, data.pin);
        if (response.status === 200) { 
            next();
        } else {
            setError("You have entered the wrong code");
            setProcessing(false);
            pinEle.clear();
        }
    }

    const resendOTP = async() => {
        pinEle.clear();

        mixpanel.track('resend-payment-code');

        setError("");
        setProcessing(true);
        const response = await context.resendPaymentOTP(payId);
        if (response.status === 200) { 
            mixpanel.track('resend-payment-code-successful');
        } else {
            mixpanel.track('resend-payment-code-failed');
        }

        setProcessing(false);
    }

    return (
        <>
        <p className={'text-3xl md:text-6xl font-black text-primary mb-6'}>{Strings.en.payment.verifyOtp.heading}</p>
        <p  className='text-base md:text-lg'>Enter the code you received on <span className='text-primary font-bold'>{context.momoNumber}</span></p>

        <form onSubmit={handleSubmit(onSubmit)} className='mt-20'>
            <Card>
                {error !== '' &&
                    <span className="flex absolute h-3 w-3 top-7 left-0 -mt-1.5 -ml-1.5">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red"></span>
                    </span>
                }
            <label className="block text-base font-medium text-dark" htmlFor="">
                {error !== '' ? <span className="text-red">{error}</span>
                : 'Verification code'}
            </label>
                <PinInput 
                    length={4} 
                    initialValue=""
                    secret 
                    disabled={processing}
                    ref={(n) => setPinEle(n)}
                    onChange={(value, index) => {
                        setValue('pin', value);
                    }} 
                    type="numeric" 
                    inputMode="number"
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                    focus={true}
                />
            </Card>

            <ResendOTP onResend={resendOTP} />

            <div className="mt-16 text-center">
                <Button>{ processing ? Spinner : 'Continue'}</Button>
            </div>
        </form>
        </>
    )
}

export default VerifyOtp
