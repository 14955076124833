import { useState } from 'react'
import PinInput from 'react-pin-input'
import { useForm } from 'react-hook-form'
import mixpanel from 'mixpanel-browser';

// Components
import Button from '../../../components/Button'
import Card from '../../../components/Card'

// Utils
import Strings from '../../../utils/strings'
import Spinner from '../../../components/Spinner';
import useBookingContext from '../../../hooks/use-booking-context';
import AuthService from '../../../services/auth-service';
import ResendOTP from '../../../components/ResendOTP';


function VerifyEmail({ next }) {
    const context = useBookingContext();
    const { handleSubmit, setValue } = useForm();
    const [processing, setProcessing] = useState(false);
    const [pinEle, setPinEle] = useState();
    const[error, setError] = useState('');

    const onSubmit = async (data) => {
        if (!data.pin) 
            return;

        setProcessing(true);

        mixpanel.track('verify-code', {
            code: data.pin
        });

        const response = await AuthService.verifyCode(context.email, data.pin);
        if(response?.status === 200) {
            const data = response.data.data;
            const lastAppointment = response.data.meta?.last_appointment;
            if (lastAppointment) { 
                context.setName(lastAppointment?.client_name);
                context.setMobileNo(lastAppointment?.phone);
            }
            context.setToken(data.session_token);
            next();
        } else {
            setError("You have entered the wrong code");
            setProcessing(false);
            pinEle.clear();
        }
    }

    const resendOTP = async() => {
        pinEle.clear();

        mixpanel.track('resend-booking-code');

        setError("");
        setProcessing(true);
        const response = await context.resendBookingOTP(context.email);
        if (response.status === 200) { 
            mixpanel.track('resend-booking-code-successful');
        } else {
            mixpanel.track('resend-booking-code-failed');
        }

        setProcessing(false);
    }

    return (
        <>
        <p className={'text-3xl md:text-6xl font-black text-primary mb-6'}>{Strings.en.bookNow.enterOtp.heading}</p>
        <p  className='text-base md:text-lg'>A validation code has been sent to <span className='text-primary'>{context.email}</span></p>
        <form onSubmit={handleSubmit(onSubmit)} className='mt-20'>
            <Card>
                {error !== '' && 
                    <span className="flex absolute h-3 w-3 top-7 left-0 -mt-1.5 -ml-1.5">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red"></span>
                    </span>
                }
            <label className="block text-base font-medium text-dark" htmlFor="">
                {error !== '' ? <span className="text-red">{error}</span>
                : 'Validation code'}
            </label>
                <PinInput 
                    length={6} 
                    initialValue=""
                    secret 
                    disabled={processing}
                    ref={(n) => setPinEle(n)}
                    onChange={(value, index) => {
                        setValue('pin', value);
                    }} 
                    type="numeric" 
                    inputMode="number"
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                    focus={true}
                />
            </Card>

            <ResendOTP onResend={resendOTP} />

            <div className="mt-16 text-center">
                <Button>{ processing ? Spinner : 'Continue'}</Button>
            </div>
        </form>
        </>
    )
}

export default VerifyEmail
