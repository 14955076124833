import { useState, useRef, useEffect } from 'react'
import ClassNames from 'classnames'

function SelectNetwork({
    handleSelect,
    defaultValue, 
    disabled
}) {
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [networkPlaceholder, setPlaceholder] = useState('Network');

    const handleItemClick = (event) => {
        const val = event.target.dataset.value;

        if (dropdownRef.current) {
            const childNodes = dropdownRef.current?.children.length;
            for(let i = childNodes-1; i>=0; i--) {
                dropdownRef.current?.children[i].classList.remove('text-teal')
            }
        }
        handleSelect(val);
        // setValue && setValue('momo_network', val);
        setShowDropdown(false);
        setPlaceholder(event.target.innerText);
        event.target.classList.add('text-teal');
    }

    useEffect(() => {
        if(defaultValue && dropdownRef.current) {
            const childNodes = dropdownRef.current?.children.length;
            for(let i=childNodes-1; i>=0; i--) {
                let el = dropdownRef.current.children[i];
              
                if(el?.dataset?.value === defaultValue)
                    setPlaceholder(el.innerText);
            }
        }
    }, [defaultValue])

    return (
        <>
            <div 
                className="relative z-20 flex items-center px-3 pt-1 pb-1 bg-gray-100 rounded-l-md cursor-pointer"
                onClick={()=> !disabled && setShowDropdown(!showDropdown)}
            >
                <span className="mr-2">{networkPlaceholder}</span>
                <svg className="w-5 h-5 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd" />
                </svg>
            </div>  


            <div ref={dropdownRef} className={ClassNames(
                {[`opacity-0 invisible z-10`]: !showDropdown},
                {[`transform translate-y-10 opacity-100`]: showDropdown},
                'transition-all', 'ease-out', 'duration-300', 
                'absolute', 'left-0', 'py-2', 'mt-2', 'bg-white',
                'divide-y', 'divide-gray-100', 
                'rounded-md', 'shadow-xl', 'w-44'
            )}>
                <span className="block px-4 py-2 text-sm text-gray-300 text-gray-700 hover:bg-gray-400 hover:bg-teal-light hover:text-teal cursor-pointer"
                    data-value="mtn"
                    onClick={handleItemClick}
                >
                    MTN
                </span>
                <span className="block px-4 py-2 text-sm text-gray-300 text-gray-700 hover:bg-gray-400 hover:bg-teal-light hover:text-teal cursor-pointer"
                    data-value="airteltigo"
                    onClick={handleItemClick}
                >
                    AirtelTigo
                </span>
                <span className="block px-4 py-2 text-sm text-gray-300 text-gray-700 hover:bg-gray-400 hover:bg-teal-light hover:text-teal cursor-pointer"
                    data-value="vodafone"
                    onClick={handleItemClick}
                >
                    Vodafone
                </span>
            </div>
        </>
    )
}

export default SelectNetwork
