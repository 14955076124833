import ClassNames from 'classnames'

import React from 'react'
import FormControl from './FormControl'



const Select = React.forwardRef(({
    data, error, className, isRequired,
    ...props}, ref) => {

        if(!data?.length)
            return null;

        
        const classes = className && className.split(' ');

        const selectedIndex = data.findIndex(c => c.selected);

        return <FormControl {...{error, isRequired, title: props.title, name: props.name}}>
            <div className='select bg-gray-dark rounded-xl px-4 py-1'>
                <select value={selectedIndex > -1 ? data[selectedIndex].id : ''}
                    ref={ref} {...props} className={ClassNames(
                        classes,
                        'bg-gray-dark', 'text-primary', 'outline-none', 'w-full', 'h-11', 'text-lg'
                    )} 
                > 
                    { data.map((d) => <option key={Math.random().toString()} value={d.id}>
                        {d.name}
                    </option>) }
                </select>
            </div>
        </FormControl>
    });

export default Select
