// Libraries
import React from 'react'

// Components
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'
import Form from '../../../components/Form'
import Input from '../../../components/Form/components/Input'
import Datepicker from '../../../components/Form/components/Datepicker'
import Checkbox from '../../../components/Form/components/Checkbox';
import spinner from '../../../components/Spinner'

// Utils
import Strings from '../../../utils/strings'
import Select from '../../../components/Form/components/Select'

// Contexts
import useBookingContext from '../../../hooks/use-booking-context';
import useAppContext from '../../../hooks/useAppContext'

function Details({ next }) {
    const context = useBookingContext();
    const {state} = useAppContext();
    let categoryId = 0;
    let channelId = 0;

    const selectedCategory = state.selectedConsultant;
    
    const [loading, setLoading] = React.useState(false);
    const [categoryLoaded, setCategoryLoaded] = React.useState(false);
    const [channelLoaded, setChannelLoaded] = React.useState(false);
    const [allowedDays, setAllowedDays] = React.useState([]);
    

    const handleCategoryChange = (event) => { 
        const id = event.target.value;
        const categoryIndex = context.categories.findIndex((c) => c.id === parseInt(id))

        let newData = [...context.categories];
        newData = newData.map((d, index) => {
            return {
                ...d,
                selected: index === categoryIndex ? true : false
            }
        })
        context.setCategories(newData);
        

        if (categoryIndex < 0) 
            return; 

        setAllowedDays(context.categories[categoryIndex]?.days_available)
    }

    const handleChannelChange = (event) => {
        const id = event.target.value;
        const channelIndex = context.channels.findIndex((c) => c.id === parseInt(id))

        let newData = [...context.channels];
        newData = newData.map((d, index) => {
            return {
                ...d,
                selected: index === channelIndex ? true : false
            }
        })
        context.setChannels(newData);
    }

    React.useEffect(() => {
        const getChannels = async() => {
            const response = await context.getChannels();
            if(response?.data?.data){
                const channels = response?.data?.data;

                channels.unshift({
                    id: '',
                    name: 'Select...'
                });

                context.setChannels(channels);
                setChannelLoaded(true);
            }
        }
        if (context?.channels?.length) {
            setChannelLoaded(true);
        } else {
            getChannels();
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        const getCategories = async() => {
            const response = await context.getCategories();
            if(response?.data?.data) { 
                const categories = response?.data?.data;

                categories.unshift({
                    id: '',
                    name: 'Select...'
                });
                
                categories.map((c) => {
                    if(c.name.replaceAll(' ', '').replaceAll('&', '-').toLowerCase() === selectedCategory){
                        c.selected = true;
                    }

                    return c;
                });
                context.setCategories(categories);
                setCategoryLoaded(true);
            }
        }
        if (context?.categories?.length) {
            setCategoryLoaded(true);
        } else {
            getCategories();
        }
        // eslint-disable-next-line
    }, []);

    const getConsultationFeeData = async() => {
        const response = await context.getFee(categoryId, channelId);
        if(response?.data?.data){
            const feeData = response?.data?.data;

            return feeData;
        }

        return false;
    }

    const onSubmit = async (data) => {
        if(loading)
            return;
        
        setLoading(true);

        categoryId = data.category;
        channelId = data.meeting_channel;

        context.setName(data.name);
        context.setMobileNo(data.mobile_no);
        context.setDescription(data.reason);
        context.setConsultantCode(data.consultant_code);
        context.setDate(data.date ?? context.date);

        context.setCategories(context.categories.map(d => {
            return (d.id === parseInt(data.category)) 
                ? {...d, selected: true} 
                : {...d, selected: false} 
        }));

        context.setChannels(context.channels.map(d => {
            return (d.id === parseInt(data.meeting_channel)) 
                ? {...d, selected: true} 
                : {...d, selected: false} 
        }));


        const feeData = await getConsultationFeeData();
        context.setFee(feeData);
        
        next();
    }

    if(!categoryLoaded && !channelLoaded) {
        return (
            <div className='p-4 bg-primary max-w-max mx-auto'>
                { spinner }
            </div>
        );
    }

    return (
        <div className='pb-4'>
            <Heading title={Strings.en.bookNow.details.heading} desc={Strings.en.bookNow.details.subHeading} />
            <Form onSubmit={onSubmit} setDefault={null}>
                {({ 
                    register,
                    formState: { errors },
                    trigger, clearErrors, handleSubmit, control,
                    setValue,
                    getValues,
                    ...formMethods
                }) => {
                    return (
                    <>
                        <Input
                            isRequired
                            {...register('name', {
                                required: {value: true, message: "Full Name"}
                            })}
                            autoComplete="off"
                            title="Full Name"
                            placeholder="e.g. Kweku Spencer"
                            error={errors?.name}
                            defaultValue={context.name}
                        />

                        <Input
                            isRequired
                            {...register('mobile_no', {
                                required: {value: true, message: "Your phone number"}
                            })}
                            autoComplete="off"
                            title="Your phone number"
                            placeholder="e.g. 0240000000"
                            error={errors?.mobile_no}
                            defaultValue={context.mobileNo}
                        />

                        <Select
                            isRequired
                            {...register("category", { required: {value: true, message: 'Select a category'} })}
                            title="Select a consultant category"
                            placeholder="Select a category"
                            error={errors?.category}
                            data={ context.categories }
                            onChange={handleCategoryChange}
                        />

                        <Input
                            {...register('reason', {
                                required: {value: true, message: "How can we help? (300 characters max)"}
                            })}
                            autoComplete="off"
                            maxLength={300}
                            title="How can we help? (300 characters max)"
                            placeholder="Type here"
                            error={errors?.reason}
                            defaultValue={context.description}
                            isMultiLine={true}
                            rows={4}
                            isRequired
                        />

                        <Select
                            isRequired
                            {...register("meeting_channel", { required: {value: true, message: 'Select your preferred meeting channel'} })}
                            title="Select your preferred meeting channel"
                            placeholder="Select a channel"
                            error={errors?.meeting_channel}
                            data={ context.channels }
                            onChange={handleChannelChange}
                        />

                        <Input
                            {...register('consultant_code')}
                            autoComplete="off"
                            title="Enter your preferred consultant's code."
                            placeholder="e.g. 0000"
                            defaultValue={context?.consultantCode}
                        />

                        <Datepicker 
                            error={errors?.date}
                            isRequired
                            name="date"
                            register={register}
                            setValue={setValue}
                            title="Date"
                            allowedDays={allowedDays}
                            {...formMethods}
                        />

                        <div className="pt-2">
                            <Checkbox {...register('terms', {required: true})} error={errors?.terms}>
                                I have read and accept the <a target='_blank' rel='noreferrer' href={`${Strings.url}/tnc`} className="text-teal underline">terms &amp; conditions</a>
                            </Checkbox>
                        </div>

                        <div className="mt-12">
                            <Button> { loading ? spinner: 'Continue' } </Button>
                        </div>

                    </>
                )}}
            </Form>
        </div>
    )
}

export default Details
