import React, { useState, useEffect } from 'react'

export default function ResendOTP({onResend}) {
    const [counter, setCounter] = useState(60);

    useEffect(() => {
        if (!counter) return;

        const countID = setInterval(() => {
            setCounter(s=>(s - 1));
        }, 1000);

        return () => clearInterval(countID);
    }, [counter])

    const handleResendClick = async() => {
        await onResend();
        setCounter(60);
    }

    return (
        <div>
            <p className="mt-4">
                Didn't get the code? {!counter 
                ? <>&nbsp;<span 
                    className="text-primary font-bold underline cursor-pointer"
                    onClick={handleResendClick}
                    >Resend</span></>
                : <><br />You can request a new code in <span className="font-bold">{("0"+counter).slice(-2)}</span></>}
            </p>
        </div>
    )
}
