// Libraried
import React from 'react'
import mixpanel from 'mixpanel-browser';

// Components
import Button from '../../../components/Button'
import Heading from '../../../components/Heading'
import spinner from '../../../components/Spinner'

// Utils
import Strings from '../../../utils/strings'
import usePaymentContext from '../../../hooks/use-payment-context';

function PaymentRequestSent({next, payId}) {

    const context = usePaymentContext();
    let timer = null;

    const registerPaymentStatusChecker = () => {
        timer = window.setTimeout(checkPaymentStatus, 2000);
    }

    const checkPaymentStatus = async () => {
        const response = await context.getPaymentStatus(payId);
        if (response?.status === 200) { 
            const payStatus = response?.data.data.momo_transaction.status;
            if (payStatus?.toLowerCase() === 'success') { 
                mixpanel.track('payment-request-success');
                next();
                return;
            } else if (payStatus?.toLowerCase() !== 'pending') { 
                mixpanel.track('payment-request-failed');
                next(6);
                return;
            }
        } 

        registerPaymentStatusChecker();
    }

    React.useEffect(() => {
        if (timer) {
            clearInterval(timer);
        }
        
        registerPaymentStatusChecker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
          <Heading title={Strings.en.payment.authorization} desc='' />  

          <div className='mt-16'>
              <p>You should receive a prompt on your phone shortly</p>
              <p>Kindly authorize to complete payment. </p>
          </div>

          <div className='mt-24'>
              <Button>{spinner}</Button>
          </div>
        </>
    )
}

export default PaymentRequestSent
