import React from 'react'
import { format } from 'date-fns'


function BookingDetails({data}) {
    const {category, meeting_channel, problem_summary, reference_number, start_time, consultant_name} = data;
    const d = new Date(start_time.replace(/-/g, "/"));

    return (
        <div>
            <p className='mt-2'>
                Consultant Name: <b>{ consultant_name }</b>
            </p>
            <p className='mt-2'>
                Consultant Category: <b>{ category }</b>
            </p>

            <p className='mt-2'>
                Meeting Channel: <b>{ meeting_channel }</b>
            </p>

            <p className='mt-2'>
                Meeting Date & Time: <b>{ format(d, "do MMM, y 'at' hh:mm aaaaa'm'") }</b>
            </p>

            <p className='mt-2'>
                Reason: <b>{ problem_summary }</b>
            </p>

            <p className='mt-2'>
                Appointment reference number: <b>{ reference_number }</b>
            </p>
        </div>
    )
}

export default BookingDetails
