import ClassNames from 'classnames'

import React from 'react'
import FormControl from './FormControl'



const Input = React.forwardRef(({
    error, className, isRequired, isMultiLine,
    ...props}, ref) => {
        
        const classes = className && className.split(' ');

        return <FormControl {...{error, isRequired, title: props.title, name: props.name}}>
            {!isMultiLine ?
                <input 
                    ref={ref} {...props} className={ClassNames(
                        classes,
                        'bg-transparent', 'border-0', 'outline-none', 'w-full', 'h-11', '-mb-5', 'text-base', 'md:text-lg'
                    )} 
                /> : 
                <textarea 
                    ref={ref} {...props} className={ClassNames(
                        classes,
                        'bg-transparent', 'border-0', 'outline-none', 'w-full', '-mb-5', 'text-lg'
                    )} 
                >
                </textarea>
            }
        </FormControl>
    });

export default Input
